import React from "react"
import {ToggleButtonGroup, ToggleButton} from "react-bootstrap"

export const Feature = ({ value, func }) => {
  if(value === null) {
    return(<></>)    
  }
  const label = value.label   
  const abbr = value.abbr != null ? " (" + value.abbr + ")" : "" 
  value = value.varesi.map((item, i) => (
    <ToggleButton key={`key_${i}`} value={i} className="rounded mr-2 mb-2">
      {typeof item === 'object' ? item.value : item}
      </ToggleButton>)
  )



  return (
    <>
      <div className="mb-1">{label}{abbr}:</div>
      <ToggleButtonGroup type="radio" name="features" className="d-block toogle-store btn-group-md mb-3" onChange={func}>
        {value}
      </ToggleButtonGroup>
    </>
  ) 
}
