import React, {useState, useEffect, useContext} from "react"
import { Button } from "react-bootstrap"
import {CartModal} from "./CartModal"
import CartContext from "../../context/CartContext"
import {WavesSVG} from "../SVG/"

import {AiOutlineShoppingCart} from "react-icons/ai"
import "../../styles/cartstyle.css"

export const CartIcon = () => {

    const [show, setShow] = useState(false);
    const {cartCount} = useContext(CartContext);

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        e.preventDefault()
    }

    const [cartModal, setCartModal] = useState(<></>)
    useEffect(() => {
        if(show) setCartModal(<CartModal toHide={handleClose}/>)
        else setCartModal(<></>)

    }, [show])

    return (
        <WavesSVG down className="position-fixed w-100 z-1030 header">
            <Button id="cart-btn" variant="primary" onMouseDown={handleShow} rel="nofollow" className="rounded-circle">
                <AiOutlineShoppingCart size="1.5rem"/>
                <div id="cart-count" className="d-flex align-items-center justify-content-center">{cartCount}</div>
            </Button>   
            {cartModal}            
        </WavesSVG>
    )
}