import React, {useContext} from "react"
import { Modal } from "react-bootstrap"
import { Link } from "gatsby"

import {CartProducts} from "./CartProducts"
import {CartSVG} from "./CartSVG"
import CartContext from "../../context/CartContext"

import {Row, Col} from "react-bootstrap"

export const CartModal = ({toHide}) => {

    const {cartItems} = useContext(CartContext);
    const {getProductList} = useContext(CartContext);

    let productList = null    
    let modalFooter = <Modal.Footer className="d-block text-center">    
                            <div className="small mb-2">Минимальная стоимость заказа - 600 руб.</div>
                        </Modal.Footer>
    
    if(cartItems !== null)
    if(cartItems.products.length > 0){     
        let proListTotal =  getProductList()
        productList = proListTotal[0]

        modalFooter = 
                    <Modal.Footer className="d-block">    
                        <Row>
                            <Col className="d-flex flex-wrap align-content-end">
                                <div>
                                    {proListTotal[1] < 600 ? <div className="small mb-2">Минимальная стоимость заказа - 600 руб.</div> : <></>}
                                    <div>Итого: {proListTotal[1]}<span className="small">руб</span></div>
                                </div>
                            </Col>    
                            {proListTotal[1] > 599 ?
                            <Col className="d-flex justify-content-end">
                                <Link className="btn btn-primary rounded" variant="primary" to="/shop/order/">
                                    Оформить заявку
                                </Link>
                            </Col>
                            :
                            <></>}
                            
                        </Row>
                    </Modal.Footer>
    }

    return (
        <>
        <Modal size={productList !== null ? "lg" : "md"} show={true} onHide={toHide} animation={false} centered>
            <Modal.Header className="border-0 justify-content-center" closeButton>
                <Modal.Title>Ваша корзина</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="px-3">
                    <Col xs={12} md className="text-center">
                        <div className="p-3">Доставка осуществляется по всей территории РФ</div>
                        <div className="blue rounded p-3">Только до 15 декабря всем покупателям из Санкт-Петербурга доставка бесплатно!</div>
                        <Row className="py-3">
                            <IcoSVG svg="clock" text="Срок доставки составляет от 4 до 7 дней"/>
                            <IcoSVG svg="shipping" text="Доставка транспортной компанией или почтой"/>
                        </Row>
                        <div className="text-muted small py-3">Расчет стоимости доставки индивидуальный и зависит от вашего города. От 300 до 500 руб.</div>
                    </Col>
                    {productList !== null ? <Col><CartProducts productList={productList}/></Col> : <></>}
                </Row>                
            </Modal.Body>
            {modalFooter}            
        </Modal>
        </>
    )
}

const IcoSVG = ({svg, text}) => {
    return (
        <Col>
            <div className="mb-3 mx-auto" style={{maxHeight: "40px", maxWidth: "40px"}}><CartSVG type={svg}/></div>
            <div className="small">{text}</div>
        </Col>
    )
}