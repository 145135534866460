import PropTypes from "prop-types"
import React from "react"
import { Product } from "."
import { Container, Row, Col } from "react-bootstrap"

export const Products = props => {
  const { nodes } = props

  return (
    <Container>
      <Row>
        {nodes.map((item, i) => (
          <Col sm={6} md={4} className="px-sm-3 my-3" key={`product_${item.slug}`}>
            <Product
                {...item}
                navigation={{
                  current: i,
                  items: nodes.map(item => `/shop/${item.slug_category}${item.slug}/`),
                }}
                setshortdesc={false}
              />
          </Col>
          ))}
      </Row>
    </Container>
  )
}

Products.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
}
