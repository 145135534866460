import React from "react"

export const WavesSVG = ({up, down, up2, className, white, children}) => {
    let wave = <></>
    let color = white ? "#fff" : "#eceff1"
    if (down) {
        wave = 
            <svg fill={color} className="wave" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" viewBox="0 1 1024 162" preserveAspectRatio="none">
                <path d="M1024.1.156H.1V3.2c50.8 17.1 108.7 30.7 172.7 37.9 178.6 19.8 220-36.8 404.9-21.3 206.6 17.2 228 126.5 434.5 141.6 3.9.3 7.9.5 11.9.7V.156z"></path>
            </svg>
    }             
    if (up){
        wave =
            <svg fill={color} xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" viewBox="0 54 1024 162" preserveAspectRatio="none">
                <path d="M0 216.312h1024v-3.044c-50.8-17.1-108.7-30.7-172.7-37.9-178.6-19.8-220 36.8-404.9 21.3-206.6-17.2-228-126.5-434.5-141.6-3.9-.3-7.9-.5-11.9-.7v161.944z"></path>
            </svg>
    }
    if (up2){
        wave = 
        <svg fill={color} xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1920 327.34">
            <path d="M0-24.34V302.78c632.81,0,1287.19.22,1920,.22-16.35-1.61-134.52-27-164.89-35-66.73-17.46-147.91-52-207.9-85.6-42.1-23.55-83.7-48-125.58-71.93a849.52,849.52,0,0,0-129.29-60.3,714.07,714.07,0,0,0-137.89-35.74c-26.18-4-52.49-7.26-78.81-10.21-15.54-1.74-31.2-2.6-46.83-3.2-25.7-1-51.42-2-77.13-2-22,.07-44.09,1.15-66.06,2.74-24.35,1.76-48.77,3.59-72.89,7.21-44.19,6.64-88.18,14.66-132.27,22-29.41,4.9-58.79,10-88.29,14.32-20.28,2.94-40.71,4.92-61.13,6.78q-21.79,2-43.7,2.39c-23.79.42-47.62,1-71.38.09-26.45-1-52.88-3.18-79.25-5.54-22.39-2-44.77-4.4-67-7.67-23-3.38-45.86-7.9-68.73-12.15-58.15-10.8-115.21-25.85-171.46-44Q14.76-19.52,0-24.34Z" transform="translate(0 24.34)"/>
        </svg>
    }
    
    return (
        <section className={className}>
            {children}
            {wave}
        </section>                
    )
  }