import Img from "gatsby-image"
import { Link } from "gatsby-plugin-modal-routing"
import React from "react"

export const Product = props => {
  const {
    name,
    images,
    navigation,
    slug,
    slug_category,
    feature,
    shortdesc,
    setshortdesc
  } = props

  let price = (props.price).toFixed(2)
  if(feature !== null) {
    price = (feature.varesi[0].price).toFixed(2) + '-' + (feature.varesi[feature.varesi.length - 1].price).toFixed(2)
  }

  let shordescription = <></>
  if(setshortdesc && shortdesc !== ''){
    shordescription = <div className="mt-4 text-center">{shortdesc}</div>
  }

  return (
      <Link to={`/shop/${slug_category}${slug}/`} state={{ navigation }} asModal>
        <div>
          <Img fluid={images.childImageSharp.fluid} alt={name} />
        </div>
        <div className="px-3 text-center">
          <h4 className="text-uppercase">
            {name}
          </h4>
          <div>{price}<span className="small"> руб.</span></div>
        </div>
        {shordescription}
      </Link>
  )
}

Product.defaultProps = {
  navigation: {},
}

