import React from "react"

export const CartSVG = ({type}) => {
    let svg = <></>
    switch (type) {
        case "clock":
            svg = 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M20.32,18.88a1.17,1.17,0,0,0-.64,2.24,1.17,1.17,0,0,0,.64-2.24Z" fill="#717a98"/><path d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,3.64a1.25,1.25,0,1,1-1.25,1.25A1.25,1.25,0,0,1,20,3.64ZM4.94,21.25A1.25,1.25,0,1,1,6.19,20,1.25,1.25,0,0,1,4.94,21.25ZM20,36.37a1.25,1.25,0,1,1,1.25-1.25A1.25,1.25,0,0,1,20,36.37ZM23.5,21a3.65,3.65,0,0,1-3.25,2.62L19,27.91a1.23,1.23,0,0,1-1.19.9,1.27,1.27,0,0,1-.34,0,1.25,1.25,0,0,1-.86-1.54L17.86,23a3.67,3.67,0,0,1-1.5-2.71L8.73,18a1.25,1.25,0,0,1,.7-2.39l7.62,2.22A3.65,3.65,0,0,1,23.5,21ZM34,21.17A1.17,1.17,0,1,1,35.21,20,1.17,1.17,0,0,1,34,21.17Z" fill="#717a98"/></svg>
            break;
        case "shipping":
            svg = 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><polygon points="36.93 21.2 24.76 21.2 24.76 31.64 20.24 28.57 15.72 31.64 15.72 21.2 3.07 21.2 3.07 40 36.93 40 36.93 21.2" fill="#717a98"/><path d="M1.53,20H38.47V7.44H29.7c.45-.18.9-.38,1.35-.59l0,0c.36-.17.78-.37,1.22-.61,2.19-1.15,4.88-3,2.82-5.39-1-1.17-3-.84-4.32-.58a17,17,0,0,0-4,1.3,17.39,17.39,0,0,0-5.91,4.67l-.47.61-.22.3-.1.16L20,7.13l-.22-.3-.47-.61a17.29,17.29,0,0,0-5.91-4.67,16.72,16.72,0,0,0-4-1.3C8.13,0,6.13-.34,5.12.83c-2.06,2.36.63,4.24,2.82,5.39.44.24.86.44,1.22.61l0,0c.44.21.89.41,1.34.59h-9ZM27.06,4.06c1.66-.89,4-1.89,5.91-1.7,1.16.11-.3.9-.65,1.12s-.91.56-1.38.81a22.51,22.51,0,0,1-3,1.33c-.69.23-1.39.43-2.09.6s-1.45.32-2.18.43l.43-.43A13.83,13.83,0,0,1,27.06,4.06ZM12.33,5.62a21.44,21.44,0,0,1-3-1.33c-.48-.25-.93-.53-1.39-.81s-1.8-1-.64-1.12c1.93-.19,4.25.81,5.9,1.7a14.14,14.14,0,0,1,3,2.16l.43.43c-.74-.11-1.47-.25-2.19-.43S13,5.85,12.33,5.62Z" fill="#717a98"/></svg>
            break;       
        default:
            break;
    }

    return svg
  }