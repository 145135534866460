import React from "react"
import { Carousel } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const QuoteCarousel = () => {
    const data = useStaticQuery(graphql`
    {
        quotes: allQuotesJson {
          nodes {
            link
            name
            text
            images {
              childImageSharp {
                fluid(maxWidth: 75, maxHeight: 75, cropFocus: NORTH) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        noimage: file(relativePath: {eq: "images/quotes/noimage.png"}){
          childImageSharp {
              fluid(maxWidth: 75, maxHeight: 75) {
              ...GatsbyImageSharpFluid_withWebp
              }
          }
        }
      }
    `)

    return (
        <Carousel 
                controls={false}   
                style={{paddingBottom : '40px'}}  
              >       
              {data.quotes.nodes.map((item, i) => (
                <SlideItem 
                  item={item} 
                  key={`quote_${i}`}
                  noimage={data.noimage}
                  className="" 
                />
                ))}

        </Carousel>         
    )
  }

  const SlideItem = ({item, noimage, className}) => {
    return (
      <Carousel.Item className={className} >
        <div className="card text-center border-0 m-2" style={{boxShadow: '0px 0px 5px #b3b3b3'}}>
          <div className="d-flex justify-content-center my-3">
            <Img 
                fluid={item.images != null ? 
                  item.images.childImageSharp.fluid 
                  : 
                  noimage.childImageSharp.fluid
                } 
                alt={item.name} 
                className="card-img-top rounded-circle"
                style={{width: '75px', height: '75px'}}
              />
          </div>
          <div className="card-title">
              {item.link == null ? item.name : 
              <a href={item.link}
              rel="noreferrer noopener nofollow" 
              target="_blank">{item.name}</a> }  
            </div>
          <div className="card-body pt-2 pb-4 px-3">
              <p className="card-text">{item.text}</p>
          </div>
        </div>
      </Carousel.Item>
    )
  }