import { graphql, useStaticQuery, Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

import { WavesSVG } from "../components/SVG/"

import {Container, Row, Col, Nav} from "react-bootstrap"
import {CartIcon} from "../components/Cart/CartIcon"

import {
  FaInstagram,
  FaVk
} from "react-icons/fa"

export const LayoutFull = ({ children, noWaveHeader, noWaveFooter }) => {
  const data = useStaticQuery(graphql`
    query FullQuery {
      site {
        meta: siteMetadata {
          links {
            contact
            instagram
            vk
          }
        }
      }
      logo: file(relativePath: {eq: "images/logo-bee.png"}){
        childImageSharp {
            fluid(maxWidth: 250, maxHeight: 155) {
            ...GatsbyImageSharpFluid
            }
        }
      }
    }
  `)

  return (
    <>
    <CartIcon/>
    <Container fluid className="blue">
      <Row className="justify-content-center py-3">
          <Col xs={10} sm={6} className="logo-link px-0">
            <Link to="/" className="mx-auto"> 
              <Img fluid={data.logo.childImageSharp.fluid} alt="Пчелиный Хуторок" />
            </Link> 
          </Col>                   
        </Row>
      <Row>
        <Col className="py-1 pb-5">
          <Nav className="justify-content-center">
            <Link to="/" className="mx-3 mb-3">Главная</Link>
            <Link to="/about/" className="mx-3 mb-3">О нас</Link>
            <Link to="/blog/" className="mx-3 mb-3">Блог</Link>
            <Link to="/paseka/" className="mx-3 mb-3">Экскурсии</Link>
            <Link to="/shop/med/" className="mx-3 mb-3">Мёд</Link>
            <Link to="/shop/" className="mx-3 mb-3">Продукция</Link>
          </Nav>
        </Col>
      </Row>
    </Container>
    {noWaveHeader ? <></> :<WavesSVG down className="position-absolute w-100"/>}

      {children}

    {noWaveFooter ? <></> : <WavesSVG up blue/>}
    <footer>
      <Container fluid className="blue">
        <Container className="text-center">
          <Row className="pt-5 justify-content-center">
            <Col lg={8}>
              <Row>

                <Col xs lg={{offset : 1}} className="d-none d-sm-block text-md-left mb-3">
                  <div><Link to="/" className="px-1 mx-1">Главная</Link></div>
                  <div><Link to="/about/" className="px-1 mx-1">О нас</Link></div>
                  <div><Link to="/blog/" className="px-1 mx-1">Блог</Link></div>
                  <div><Link to="/shop/med/" className="px-1 mx-1">Наш мед</Link></div>
                  <div><Link to="/paseka/" className="px-1 mx-1">Экскурсии</Link></div>
                  <div><Link to="/shop/" className="px-1 mx-1">Продукция</Link></div>
                </Col>
                <Col className="mb-3">
                  <Row>
                    <Col xl={12}>
                      <a href="tel:+7 911 381-04-75" rel="noreferrer noopener nofollow">+7(911)381-04-75</a>
                      <p>г. Великие Луки<br/>ул. Комсомольца Зверева, 27</p>
                    </Col>
                    <Col className="justify-content-center pb-3">
                      <FooterIconLink
                        href={data.site.meta.links.instagram}
                        icon={FaInstagram}
                        label="Instagram"
                      />
                      <FooterIconLink
                        href={data.site.meta.links.vk}
                        icon={FaVk}
                        label="VK"
                      />
                    </Col>
                  </Row>
                
                </Col>
                <Col md={{offset : 1}} className="text-lg-right mb-4">
                  ИП Нестеров А. А.<br/>
                  <small>ОГРНИП: 315602700009862<br/> 
                  от 4 августа 2015г.<br/>
                  ИНН: 602508926401 </small>         
                </Col>
                
              </Row>            
            </Col>
          </Row>        
        </Container> 
      </Container> 
    </footer>
    </>
  )
}


const FooterIconLink = ({ href, label, icon: Icon }) => {
  const linkParams = { href }

  if (href.startsWith("http")) {
    linkParams.target = "_blank"
    linkParams.rel = "noreferrer noopener nofollow"
  }

  return (
    <a
        {...linkParams}
        className="d-inline-flex rounded-circle justify-center footer-icon m-2"
      >
        <span className="sr-only">{label}</span>
        <Icon className="" size="1.25rem" />
      </a>
  )
}