import PropTypes from "prop-types"
import React, {useState} from "react"
import Slider from "react-slick"
import { Product } from "."
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

import "../styles/product-carousel.css"

export const ProductsCarousel = (props) => {  
    const { nodes } = props
    const [shuffleCount, setShuffleCount] = useState(0)

    const shuffle = (arr) => {
      if (shuffleCount > 0) return arr
      var j, temp;
      for(var i = arr.length - 1; i > 0; i--){
        j = Math.floor(Math.random()*(i + 1))
        temp = arr[j]
        arr[j] = arr[i]
        arr[i] = temp
      }
      setShuffleCount(1)
      return arr
    }
    const arrayNodes = shuffle(nodes)
    
    const arrayProducts = arrayNodes.map((item, i) => (
            <Product
                {...item}
                navigation={{
                current: i,
                items: arrayNodes.map(item => `/shop/${item.slug_category}${item.slug}/`),
                }}
                setshortdesc={true}
            />       
    ))


    var settings = {
        nextArrow: <FaChevronRight/>,
        prevArrow: <FaChevronLeft/>,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3
              }
            }
          ]
    }

    return(
            <Slider {...settings} className="mb-3">
                {arrayProducts.map((item, i) => (
                <div className="px-sm-3 my-3" key={`product_${i}`}>{item}</div>
            ))}
            </Slider>
    )
}

    

ProductsCarousel.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
}
 