import React from "react"
import { Row, Col, Carousel } from "react-bootstrap"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import Img from "gatsby-image"

import "../styles/product-carousel.css"

export const GalleryCarousel = ({images, alt}) => { 
    let subarray = []
    images.map((item, i) => {
      subarray.push(<Col><Img fluid={item.node.childImageSharp.fluid} alt={alt}/></Col>) 
    })
    let slidesArray = []

    let width = typeof window !== 'undefined' ? window.innerWidth : 1200

    let colSize =  width >= 768 ? 2 : 1
    
    for (let i = 0; i <Math.ceil(subarray.length/colSize); i++){
        slidesArray[i] = subarray.slice((i*colSize), (i*colSize) + colSize);
    }
    return (
        <Carousel 
            interval={5000} 
            indicators={null} 
            prevIcon={<FaChevronLeft/>}
            nextIcon={<FaChevronRight/>}
          >            
            {slidesArray.map((item, i) => (
                <Carousel.Item key={`carousel_${i}`}><Row>{item}</Row></Carousel.Item>
            ))}
          </Carousel>  
    )
}