import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

export const SiteMetadata = ({ description, image, title, canonical }) => {
  const {
    site: {
      siteMetadata: { siteUrl, locale, siteTitle },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          locale
          siteTitle: title
        }
      }
    }
  `)

  const urlCanonical = siteUrl + canonical

  return (
    <Helmet
      defer={false}
      defaultTitle={siteTitle}
      title={title}
      titleTemplate={`%s - ${siteTitle}`}
    >
      <html lang={locale} />
      <link rel="canonical" href={urlCanonical}/>
      
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={urlCanonical} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={image} />
      <meta property="twitter:description" content={description} />

      <script type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Пчелиный Хуторок",
              "url": "https://beehoney.farm/",
              "email" : "info@beehoney.farm",
              "image" : "https://beehoney.farm/logo-bee.png",              
              "telephone": "+79113810475",
              "sameAs": ["https://www.facebook.com/beehoney.farm", 
                        "https://www.instagram.com/beehoney.farm/",
                        "https://vk.com/beehoney.farm"]
            }
          `}
      </script>

    </Helmet>
  )
}

SiteMetadata.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
}

SiteMetadata.defaultProps = {
  image: "/social.jpg",
}
