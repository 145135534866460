import React, {useContext} from "react"
import CartContext from "../../context/CartContext"

import {Col, Button} from "react-bootstrap"
import Img from "gatsby-image"

export const CartProducts = ({productList}) => {
    const {deleteFromCart} = useContext(CartContext)
    const {changeQTY} = useContext(CartContext)

    const closeStyle = {
        position: "absolute",
        top: "0",
        right: "0.25rem"
    }

    const liStyle = {
        borderTop: "1px solid rgb(222 226 230 / .5)"
    }

    const clickDelete = (e, id) => {
        e.preventDefault()
        deleteFromCart(id)
        return false
    }

    const changeCount = (e, status, productID, count) => {
        e.preventDefault()
        switch (status) {
            case 'minus':
                if(count-1 > 0) changeQTY(productID, -1)
                break;        
            default:
                changeQTY(productID, 1)
                break;
        }
        return false
    }

    if(productList !== null){
        productList = productList.map((product, index) => {
            if(product !== null) {                
                try {      
                    let choosenVares = product.vares != null ? <div className="small">{product.vares}</div> : <></>
                       
                    let choosenFeature = product.feature != null ? <div className="small">{product.feature}{product.abbr}</div> : <></>
                        
                    return <li key={`prod_${index}`} className="row py-2 mx-0" style={liStyle}>
                        <Col xs={4} className="d-flex align-items-center p-0">
                            <div className="w-100"><Img fluid={product.images.childImageSharp.fluid} alt={product.name}/></div>
                        </Col>
                        <Col xs={5} className="d-flex flex-wrap align-content-center px1">
                            <div>
                                {product.name}
                                {choosenFeature}
                                {choosenVares} 
                                <div className="small mt-1">
                                    <Button variant="primary" className="rounded" disabled={product.qty == 1} style={{padding: ".3rem .5rem", marginRight: ".3rem", lineHeight: ".9rem"}} onClick={ (e) => changeCount(e, 'minus', product.id, product.qty)}>-</Button>
                                    {product.qty} шт
                                    <Button variant="primary" className="rounded" style={{padding: ".3rem .5rem", marginLeft: ".3rem", lineHeight: ".9rem"}} onClick={ (e) => changeCount(e, 'plus', product.id, product.qty)}>+</Button>
                                </div>   
                            </div>                                                 
                        </Col>
                        <Col xs={3} className="d-flex px-1">
                            <button type="button" className="close" style={closeStyle} aria-label="Delete" onClick={(e) => clickDelete(e, product.id)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="align-self-end w-100 text-right">{product.price*product.qty}<span className="small">руб.</span></div>
                        </Col>                        
                    </li> 
                } catch (e) {
                    console.log(e)
                    return <></>
                }            
            } else return <></>
        })

        return (<ul className="cart-product-ul mx-0 px-0">{productList}</ul>)
    } else return(<div>Ваша корзина пуста!</div>)
}